@import '../../../scss/theme-bootstrap';

.full-width-promo-v1 {
  margin: 0;

  // Mobile accomodations //
  .device-mobile & {
    margin: 0;
    padding: 0;
  }
  .flexslider {
    padding-bottom: 25px;
    max-width: 100%;
    padding: 0;
    &,
    &:hover {
      // no need to use left and right variables for RTL sites, the left arrow will always point left.. SH //
      .flex-direction-nav .flex-prev {
        left: 25px;
      }
      .flex-direction-nav .flex-next {
        right: 25px;
      }
    }
    .flex-control-nav.flex-control-paging {
      // the pager "dots" at the bottom
      bottom: 15px;
    }
  }
}
/* DoneDone #295 - Holiday Shop 2015 Homepage - v2 - Brand needs a full width slider. Setting the viewport "max-width" to none to be able to override the itemWidth */

.full-width-promo-v1.customSliderWidth {
  .flex-viewport {
    max-width: none !important;
  }
}
/* DoneDone #308 - CMS Template Modifications - Full Width - to set a 100% full width screen slider. */

.full-width-promo-v1.fullScreenSliderWidth {
  .flexslider {
    padding: 0;
  }
  .flex-viewport {
    max-width: none !important;
  }
}
/* Mobile HP, Get the Look section */
/* Brand requested the bottom padding on mobile HP modules to be removed */
/* Brand requested mobile homepage and the GTL section modules to be full width - DoneDone #47, #64 */
.front.device-mobile .full-width-promo-v1 .flexslider.item,
.section-get-the-look.device-mobile .full-width-promo-v1 .flexslider.item {
  padding: 0;
}

.front.device-mobile .full-width-promo-v1 .flexslider.items,
.section-get-the-look.device-mobile .full-width-promo-v1 .flexslider.items {
  padding-bottom: 45px;
}
/* promo full offer css change */
.cc-cream-more-info-link {
  display: none;
}

.tab-switcher {
  .cc-cream-more-info-link {
    display: none;
  }
  .promo_pick_full.cc-cream-more-info-link {
    display: none;
  }
}

.samples-overlay {
  .full-width-promo-v1 .basic-image-v1 img {
    width: 100%;
    padding-top: 20px;
  }
  #quickshop .close {
    right: 10px;
    top: 4px;
  }
  .promo_pick_full.cc-cream-more-info-link {
    padding-top: 10px;
  }
  .flexslider {
    padding-left: 0;
    padding-right: 0;
  }
}

.device-mobile {
  .cc-cream-more-info-link {
    display: none;
  }
  .promo_pick_full.cc-cream-more-info-link {
    display: none;
  }
  // CX-8780 Promo Flex Control Fix
  .full-width-promo-v1 {
    .flex-control-paging li a {
      @include swap_direction(margin, 0 auto);
      position: relative;
    }
  }
}

.promo_pick_full.cc-cream-more-info-link {
  display: block;
}
